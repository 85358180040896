<template>
    <UtilityButton @clicked="onClicked" icon="link" class="copy-link-button">
        <template #message>
            <div class="copied-message">{{ $t('link copied') }}</div>
        </template>
    </UtilityButton>
</template>

<script setup>
const onClicked = () => {
    if (process.client) {
        navigator.clipboard.writeText(window.location).then(
            () => {},
            (err) => {
                console.error('Could not copy link', err);
            },
        );
    }
};
</script>

<style lang="scss" scoped>
.copy-link-button {
    position: relative;
}
.copied-message {
    outline: 1px solid palette(border-light);
    border-radius: 0.4rem;
    background-color: color(blanc);
    padding: vertical-space(0.5);
    transform: translate(-50%, -0.8rem);
}
</style>
