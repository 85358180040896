<template>
    <button @click="onClick" class="utility-button">
        <Icon v-if="icon" :name="icon" class="icon" />

        <Typography v-if="$slots.message" v-show="isMessageVisibile" variant="label-small" class="message">
            <slot name="message"></slot>
        </Typography>
    </button>
</template>

<script setup>
const isMessageVisibile = ref(false);

const emit = defineEmits(['clicked']);

const props = defineProps({
    icon: {
        type: String,
        default: '',
    },
});

const onClick = () => {
    emit('clicked');
    isMessageVisibile.value = true;
    setTimeout(() => (isMessageVisibile.value = false), 1000);
};
</script>

<style lang="scss" scoped>
.utility-button {
    padding: 1.425rem;
    border: 0.15rem solid;
    border-color: color(blue, default, 0.25);
    border-radius: 100rem;
    width: 4.2rem;
    height: 4.2rem;
    aspect-ratio: 1/1;
    position: relative;
    @include transition(border-color, controls);

    .icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: color(blue, default, 0.25);
        @include transition(color, controls);
    }

    &:hover {
        border-color: color(teal, hover);
        .icon {
            color: color(teal, hover);
        }
    }
    &:active,
    &.active {
        border-color: color(teal, active);
        .icon {
            color: color(blue, default);
        }
    }
}

.message {
    position: absolute;
    bottom: 100%;
}
</style>
